import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: var(--header-width);
  margin: 0 auto;
`;

export const Container = styled.section`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: var(--font-regular);
  text-align: justify;
  margin-bottom: 3rem;

  h1 {
    font-family: var(--font-bold);
    color: var(--primary-color);
    font-size: 3.5rem;
    line-height: 5rem;
    vertical-align: top;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    margin-top: 20px;
    font-size: 1.3rem;
  }

  h4 {
    font-size: 1rem;
  }

  a {
    text-decoration: underline solid blue;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  @media (max-width: 720px) {
    h1 {
      font-size: 3.5rem;
      line-height: 3.8rem;
      padding-bottom: 20px;
    }
    flex-direction: column;
    padding-top: 2rem;
    > div {
      width: 100%;
    }
  }
`;
