import React from 'react';
import { Container } from './styles';
import { Wrapper } from '../../components/globalElements';
import Layout from '../../components/Layout/Layout';
import { StructuredText } from 'react-datocms';

const PrivacyTerms = ({ pageContext: { siteTitle, body } }) => {
  return (
    <Layout title={siteTitle}>
      <Wrapper>
        <Container>
          <h1>{siteTitle}</h1>
          <StructuredText data={body.value} />
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default PrivacyTerms;
